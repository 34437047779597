// Imports => React
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import loadable from '@loadable/component';
import clsx from 'clsx';

// Imports => Constants
import {
	ICONS,
	KEYS,
	PERMISSIONS,
	ROUTES,
	THEMES,
	TITLES,
	TYPES,
	VARIANTS,
	VISUALS,
} from '@constants';

// Imports => Utilities
import { AcIsSet, AcIsNull, AcIsEmptyString, AcUUID } from '@utils';

// Imports => Hooks
import { usePermissions } from '@hooks';

// Imports => Molecules
const AcCheckPermissions = loadable(() =>
	import('@molecules/ac-check-permissions/ac-check-permissions.web')
);
const AcAddHydrohammerModal = loadable(() =>
	import('@molecules/ac-add-hydrohammer-modal/ac-add-hydrohammer-modal.web')
);
const AcAddVibratoryHammerModal = loadable(() =>
	import(
		'@molecules/ac-add-vibratory-hammer-modal/ac-add-vibratory-hammer-modal.web'
	)
);
const AcAddPowerpackModal = loadable(() =>
	import('@molecules/ac-add-powerpack-modal/ac-add-powerpack-modal.web')
);
const AcAddSleeveModal = loadable(() =>
	import('@molecules/ac-add-sleeve-modal/ac-add-sleeve-modal.web')
);
const AcAddPulseModal = loadable(() =>
	import('@molecules/ac-add-pulse-modal/ac-add-pulse-modal.web')
);
const AcAddControlUnitModal = loadable(() =>
	import('@molecules/ac-add-control-unit-modal/ac-add-control-unit-modal.web')
);

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
const AcRichContent = loadable(() =>
	import('@atoms/ac-rich-content/ac-rich-content.web')
);
const AcButton = loadable(() => import('@atoms/ac-button/ac-button.web'));
const AcIcon = loadable(() => import('@atoms/ac-icon/ac-icon.web'));

const _CLASSES = {
	MAIN: 'ac-add-equipment-modal',
	CONTENT: 'ac-add-equipment-modal__content',
};

const AcAddEquipmentModal = ({
	store: {
		ui,
		hydrohammers,
		vibratory_hammers,
		powerpacks,
		sleeves,
		pulses,
		control_units,
		jeanlutz,
		companies,
	},
	callback,
}) => {
	const navigate = useNavigate();
	const { can, cannot } = usePermissions();

	const [eqtype, setEqType] = useState(null);

	useEffect(() => {
		if (can(PERMISSIONS.EQUIPMENT.MANAGE_DETAILS))
			companies.list({ per_page: 999, options: true });
	}, []);

	useEffect(() => {
		if (AcIsSet(eqtype)) renderAddModal();
	}, [eqtype]);

	const handleSetEQType = (event, type) => {
		if (!AcIsSet(type)) return;

		setEqType(type);
	};

	const handleCancel = async (event) => {
		if (event && event.preventDefault) event.preventDefault();
		await ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
	};

	const renderAddModal = useCallback(() => {
		if (!AcIsSet(eqtype)) return null;

		let result = null;

		switch (eqtype) {
			case KEYS.HYDROHAMMER:
				result = <AcAddHydrohammerModal submit={hydrohammers.store} />;
				ui.setValue(KEYS.MODAL, KEYS.TITLE, TITLES.NEW_HYDROHAMMER);
				break;

			case KEYS.VIBRATORY_HAMMER:
				result = <AcAddVibratoryHammerModal submit={vibratory_hammers.store} />;
				ui.setValue(KEYS.MODAL, KEYS.TITLE, TITLES.NEW_VIBRATORY_HAMMER);
				break;

			case KEYS.POWERPACK:
				result = <AcAddPowerpackModal submit={powerpacks.store} />;
				ui.setValue(KEYS.MODAL, KEYS.TITLE, TITLES.NEW_POWERPACK);
				break;

			case KEYS.SLEEVE:
				result = <AcAddSleeveModal submit={sleeves.store} />;
				ui.setValue(KEYS.MODAL, KEYS.TITLE, TITLES.NEW_SLEEVE);
				break;

			case KEYS.PULSE:
				result = <AcAddPulseModal submit={pulses.store} />;
				ui.setValue(KEYS.MODAL, KEYS.TITLE, TITLES.NEW_PULSE);
				break;

			case KEYS.CONTROL_UNIT:
				result = <AcAddControlUnitModal submit={control_units.store} />;
				ui.setValue(KEYS.MODAL, KEYS.TITLE, TITLES.NEW_CONTROL_UNIT);
				break;

			default:
		}

		const modal = Object.assign({}, ui.current_modal);
		ui.set(KEYS.MODAL, {
			...modal,
			body: result,
			id: AcUUID(),
			callback: async () => {
				await ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
			},
		});

		return result;
	}, [eqtype]);

	const getCTAButtonOptions = useCallback((key) => {
		return {
			title: 'Add equipment',
			callback: (event) => handleSetEQType(event, key),
			className: 'ac-button--pitch-cta',
		};
	}, []);

	const getContentClassNames = useMemo(() => {
		return clsx([_CLASSES.CONTENT]);
	}, []);

	const getStyleClassNames = useMemo(() => {
		return clsx([_CLASSES.MAIN]);
	}, []);

	return (
		<div className={getStyleClassNames}>
			<div className={getContentClassNames}>
				<AcContainer fluid>
					<AcRow>
						<AcColumn xs={12} className={'h-margin-bottom-20'}>
							<AcRichContent
								content={'<p>What equipment would you like to add?</p>'}
							/>
						</AcColumn>
					</AcRow>

					<AcRow>
						<AcColumn xs={12}>
							<div className={'ac-button-cta-wrp'}>
								<AcButton {...getCTAButtonOptions(KEYS.HYDROHAMMER)}>
									<AcIcon icon={ICONS.HAMMER} />
									<span>Hydrohammer</span>
								</AcButton>

								<AcButton {...getCTAButtonOptions(KEYS.VIBRATORY_HAMMER)}>
									<AcIcon icon={ICONS.VIBROHAMMER_OUTLINE} />
									<span>Vibratory hammer</span>
								</AcButton>

								<AcButton {...getCTAButtonOptions(KEYS.POWERPACK)}>
									<AcIcon icon={ICONS.POWERPACK} />
									<span>Powerpack</span>
								</AcButton>

								<AcButton {...getCTAButtonOptions(KEYS.SLEEVE)}>
									<AcIcon icon={ICONS.SLEEVE_OUTLINE} />
									<span>Sleeve</span>
								</AcButton>

								<AcButton {...getCTAButtonOptions(KEYS.PULSE)}>
									<AcIcon icon={ICONS.PULSE_OUTLINE} />
									<span>PULSE</span>
								</AcButton>

								<AcButton {...getCTAButtonOptions(KEYS.CONTROL_UNIT)}>
									<AcIcon icon={ICONS.CONTROL_UNIT} />
									<span>Control unit</span>
								</AcButton>
							</div>
						</AcColumn>
					</AcRow>
				</AcContainer>
			</div>
		</div>
	);
};

export default withStore(observer(AcAddEquipmentModal));
