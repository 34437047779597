// Imports => React
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import loadable from '@loadable/component';
import clsx from 'clsx';

// Imports => Constants
import { KEYS, PERMISSIONS, ROUTES, THEMES, TYPES, VARIANTS } from '@constants';

// Imports => Utilities
import { AcIsSet } from '@utils';

// Imports => Hooks
import { usePermissions, useFormActions } from '@hooks';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
const AcButton = loadable(() => import('@atoms/ac-button/ac-button.web'));
const AcLoader = loadable(() => import('@atoms/ac-loader/ac-loader.web'));

const _CLASSES = {
	MAIN: 'ac-add-configuration-modal',
	CONTENT: 'ac-add-configuration-modal__content',
};

let delay = null;

const EQUIPMENT_GROUP = {
	CONTROL_UNITS: 3,
	HYDROHAMMERS: 1,
	POWERPACKS: 2,
};

const AcAddConfigurationModal = ({
	store: { ui, companies, configurations, equipment, profile },
	initial_company = null, // object, contains <id>
	submit,
	callback,
}) => {
	const { can, cannot } = usePermissions();
	const navigate = useNavigate();

	const {
		current_profile: { is_iqip_user },
	} = profile;

	/*
	    {
	      name: <string>,
	      company_id: <id>,
	      equipment: {
	      	control_units: [<array>],
	      	powerpacks: [<array>],
	      	hydrohammers: [<array>],

	      	=> future addition
	      	vibratory_hammers: [<array>],
	      	sleeves: [<array>],
	      	pulses: [<array>],
	      },
	    })
	*/

	const [setup, setSetup] = useState({
		name: '',
		company_id: AcIsSet(initial_company?.id) ? initial_company?.id : null,
		equipment: {
			control_units: [],
			powerpacks: [],
			hydrohammers: [],
		},
	});

	useEffect(() => {
		if (is_iqip_user && can(PERMISSIONS.COMPANY.READ)) {
			companies.list({ options: true });
		}
	}, [is_iqip_user]);

	const fetchControlUnits = async (company_id) => {
		return await equipment.list({
			company_id,
			group: EQUIPMENT_GROUP.CONTROL_UNITS,
			options: true,
		});
	};

	const fetchPowerpacks = async (company_id, compatible_with) => {
		return await equipment.list({
			company_id,
			compatible_with,
			group: EQUIPMENT_GROUP.POWERPACKS,
			options: true,
		});
	};

	const fetchHydrohammers = async (company_id) => {
		return await equipment.list({
			company_id,
			group: EQUIPMENT_GROUP.HYDROHAMMERS,
			options: true,
		});
	};

	const handleSubmit = () => {};

	const handleCancel = () => {};

	const handleSelectCompany = () => {};

	const handleSelectEquipment = () => {};

	return null;
};

export default withStore(observer(AcAddConfigurationModal));
